import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PromoCodeList from './PromoCodeList';

const DashboardComponents = ({ setLoading }) => {
  const [email, setEmail] = useState('promo@jemputlah.com');

  useEffect(() => {
    if(email) {
      const response = axios.post('https://api.jemputlah.com/api/resellers/show', {
        email: email,
      })
        .then(response => {
          setEmail(response.data.reseller.email);
          setLoading(false);
          // console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    } else {
      console.log("No data");
    }
  }, [setEmail, setLoading]);

  const handleJemputlah = () => {
    window.open('https://jemputlah.com', '_blank');
  };

  return (
    <div className="bg-gray-50 flex pt-20 justify-center min-h-screen">
      <div className=" px-8 py-8 max-w-md w-full space-y-4">
        <h2 className='text-xl'>Step 1:</h2>
        <p>Buat Kad Kahwin Digital di Jemputlah.com</p>
        <button onClick={handleJemputlah} className='bg-red-400 hover:bg-red-500 w-full text-white text-center py-2 rounded-lg'>
          Klik Sini
        </button>
        <h2 className='text-xl'>Step 2:</h2>
        <p>Salin mana-mana salah satu Promo Code dibawah untuk dapat harga diskaun.</p>
        <PromoCodeList email={email} />
      </div>
    </div>
  );
};

export default DashboardComponents;

import React from 'react';
import logo from '../img/logo-red.png';
import { FaSpinner } from 'react-icons/fa';

const Loading = () => {

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5 bg-white">
      <FaSpinner className="text-4xl text-red-400 animate-spin" />
      <img src={logo} alt="Logo" className="h-8 mb-4" />
    </div>
  );
};

export default Loading;

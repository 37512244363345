import React, { useEffect, useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';

const PromoCodeList = ({ email }) => {
  const [promoCodes, setPromoCodes] = useState({
    rm10: [],
    rm20: [],
    rm30: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const response = await axios.post('https://api.jemputlah.com/api/bulk/promo-codes', { email });
        setPromoCodes(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load promo codes');
        setLoading(false);
      }
    };

    fetchPromoCodes();
  }, [email]);

  if (loading) return <div>Loading promo codes...</div>;
  if (error) return <div>{error}</div>;

  const renderPromoCodes = (codes, title) => (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      {codes.length > 0 ? (
        <div className="w-full"> {/* Allows horizontal scrolling on small screens */}
          <ul className="flex flex-wrap space-x-2"> {/* 'flex' for horizontal layout, 'space-x-4' for spacing, 'min-w-max' prevents items from shrinking */}
          {codes
          .sort((a, b) => a.claimed - b.claimed) // Sort by claimed: 0 first, 1 second
          .map((code, index) => (
            <li key={index} className="">
              <div
                className={`text-sm p-1 my-1 border rounded-lg relative ${
                  code.claimed === 1 ? 'bg-red-400' : 'bg-gray-100'
                }`}
                onClick={code.claimed === 1 ? null : () => copyToClipboard(code.code)} // Disable onClick if claimed
                style={{ cursor: code.claimed === 1 ? 'not-allowed' : 'pointer' }} // Change cursor to 'not-allowed' if claimed
              >
                {code.code}
                {code.claimed === 1 && (
                  <span
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white font-bold uppercase"
                    style={{ transform: 'translateY(-50%) rotate(-15deg)' }}
                  >
                    Claimed
                  </span>
                )}
              </div>
            </li>
          ))}

          </ul>
        </div>
      ) : (
        <p>No promo codes available.</p>
      )}
    </div>
  );

  const copyToClipboard = (promoCode) => {
    navigator.clipboard.writeText(promoCode).then(() => {
      alert(`Promo code ${promoCode} copied to clipboard!`);
    }).catch((error) => {
      console.error('Failed to copy promo code:', error);
    });
  };

  return (
    <div className="py-6">
      <div className="space-y-8"> 
        {renderPromoCodes(promoCodes.rm10, 'Promo RM10 (sah sehingga 11:59 malam ini sahaja)')}
        {renderPromoCodes(promoCodes.rm20, 'Promo RM20 (sah sehingga 11:59 malam ini sahaja)')}
        {renderPromoCodes(promoCodes.rm30, 'Promo RM30 (sah sehingga 11:59 malam ini sahaja)')}
      </div>
    </div>
  );
};

export default PromoCodeList;
